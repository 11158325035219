<template>
  <div>
    <el-card>
      <nav class="input">
        <el-input v-model="keyword" size="medium" placeholder="输入发布人姓名，标题，内容搜索" />
      </nav>
      <nav class="input" style="width: 400px">
        <el-button size="small" @click="reset">重置</el-button>
        <el-button type="primary" size="small" @click="getlist()">{{ "搜索" }}</el-button>
        <el-button
            size="small"
            v-show="$store.state.btn_if.indexOf('link_change_add') != -1"
            type="primary"

            @click="dialogVisiblexui = true"
        >新增短链接</el-button
        >
      </nav>
    </el-card>

    <el-card style="margin-top: 10px">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @sort-change="sortChange"
      >
        <!-- <el-table-column type="selection"></el-table-column> -->
        <el-table-column prop="id" width="100" label="编号" />
        <el-table-column prop="code" label="code" width="180" />
        <el-table-column prop="longUrl" label="原链接" />
        <el-table-column prop="longUrl" label="短链接">
          <template slot-scope="ew">
            <a
              :href="
                (ew.row.direct.includes('http:') ? '' : 'http://') +
                  ew.row.direct
              "
              target="“_blank”"
              >{{ ew.row.direct }}</a
            >
          </template>
        </el-table-column>
        <el-table-column prop="clickViews" label="点击量"  />

        <el-table-column prop="createTime" label="创建时间" sortable="custom" />

        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- <el-button type="text" icon="el-icon-edit" size="mini" @click="edit(scope.row.id)" class="skyblue">编辑</el-button> -->
            <el-button
              v-show="$store.state.btn_if.indexOf('link_change_delete') != -1"
              type="text"
              icon="el-icon-delete"
              size="mini"
              class="red"
              @click="open(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page.sync="currentPage"
        :total="total"
        :page-size="pagesize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>

    <el-dialog title="新增短链接" :visible.sync="dialogVisiblexui" width="50%">
      <el-form ref="form" :model="form_1" label-width="120px">
        <el-form-item label="长链接地址">
          <el-input v-model="form_1.url" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisiblexui = false
            form_1 = {}
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="addedit">{{
          btn ? '确 定' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改友情链接" :visible.sync="dialogVisible" width="50%">
      <el-form :model="form" label-width="120px">
        <el-form-item label="友情链接名称">
          <el-input v-model="form.linkName" />
        </el-form-item>
        <el-form-item label="链接地址">
          <el-input v-model="form.linkUrl" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add">{{
          btn ? '确 定' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {
      btn: true,
      currentPage: 1,
      pagesize: 10,
      list: [],
      keyword:'',
      form: {
        id: '',
        linkName: '',
        linkUrl: ''
      },
      form_1: {
        url: ''
      },
      total: 0,
      dialogVisible: false,
      dialogVisiblexui: false,
      paiform: {}
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    reset(){
      this.keyword=''
      this.getlist()
    },
    rowClass() {
      return 'background:#F5F7FA;'
    },
    async handleInputBlur(row) {
      this.paiform = row
      try {
        const { data: res } = await this.$http.post(
          '/admin/FriendLink/edit',
          this.paiform
        )
        if (res.errorCode == 200) {
          this.$message.success(res.message)
          //  row.pai=false
          //  this.tableData=[...this.tableData]
          this.getlist()
        }
      } catch (e) {
        // this.$message.error(e)
      }
      console.log(this.tableData)
    },
    getlist() {
      this.$http
        .get('/admin/ShortUrl/getList', {
          params: {
            page: this.currentPage,
            pageSize: this.pagesize,
            order: this.order,
            keyword:this.keyword
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            console.log(res.data.list)
            this.list = res.data.list
            this.total = res.data.totalCount
          }
        })
    },
    showinput(row, refName) {
      console.log(row, refName, this.$refs[refName])
      row.pai = true
      this.list = [...this.list]
      setTimeout(() => {
        $('.id input').focus()
      }, 0)
    },
    edit(e) {
      this.form.id = e
      this.dialogVisible = true
      this.$http
        .get('/admin/FriendLink/getById?id=' + e)
        .then(({ data: res }) => {
          this.form.linkName = res.data.linkName
          this.form.linkUrl = res.data.linkUrl
        })
    },
    add() {
      if (this.btn) {
        this.$http
          .post('/admin/FriendLink/edit', this.form)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.getlist()
              this.$message.success(res.message)
              this.dialogVisible = false
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    open(id) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .delete('/admin/ShortUrl/del', { params: { id: id } })
            .then(res => {
              //   console.log(res)
              if (res.data.errorCode == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                if (this.total % 10 == 1) {
                  this.currentPage--
                }
                this.getlist()
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    sortChange(c) {
      console.log(c)
      if (c.order) {
        var s = 'activity_' + c.prop
        if (c.prop == 'orderNum') {
          s = 'fl_order_num'
        } else {
          s = 'su_create_time'
        }
        var p = c.order.slice(0, c.order.length - 6)
        this.order = s + ' ' + p
      } else {
        this.order = ''
      }

      this.getlist()
    },
    handleSizeChange(e) {
      this.pagesize = e
      this.getlist()
    },
    handleCurrentChange(e) {
      this.currentPage = e
      this.getlist()
    },
    addedit() {
      if (this.btn) {
        this.$http
          .post('/admin/ShortUrl/add', this.form_1)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              this.dialogVisiblexui = false
              location.reload()
            }
            this.btn = true
          })
      }
      this.btn = false
    }
  }
}
</script>

<style lang="less" scoped>
.upload {
  width: 200px;
  height: 200px;
}
.skyblue {
  color: skyblue;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
.red {
  color: red;
}
</style>
